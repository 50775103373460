@import "../../stylesheets/modules/modules";

.sign-in{
  align-items: center;
  display: flex;
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  padding: to-rem(75px) to-rem(15px);
  position: relative;
  width: 100%;

  .data-card{
    margin: 0 auto;
    max-width: to-rem(600px);
    padding: to-rem(70px);
    width: 100%;

    @include phone{
      padding: to-rem(64px) to-rem(32px);
    }

    &__title{
      font-size: to-rem(28px);
      margin: 0 0 to-rem(32px);
      text-align: center;
      line-height: 120%;
    }

    &__content{
      margin: 0 auto;
    }

    .create-account{
      display: block;
      margin: to-rem(25px) 0 0;
      text-align: center;

      a{
        font-weight: $font-semibold;
        text-decoration: underline;

        &:hover{
          text-decoration: none;
        }
      }
    }

    .button{
      min-width: 150px;
      margin-top: to-rem(40px);
    }
  }

  .center-text{
    text-align: center;
  }
}
