@import '../../stylesheets/modules/modules';

.data-card {
  .main {
    padding: to-rem(50px);

    @include phone {
      padding: to-rem(20px);
    }
  }
}

.settings-menu {
  overflow: auto;
  position: relative;

  @include custom-scroll();

  &__holder {
    position: relative;

    &::after {
      background: rgba($text-color-2, 0.5);
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 0;
      width: 100%;
    }
  }

  @include phone {
    display: flex;
  }

  a {
    border-bottom: 5px solid transparent;
    color: $text-color-2;
    font-weight: $font-semibold;
    margin: 0 to-rem(30px) -2px;
    padding: to-rem(24px) 0;
    position: relative;
    white-space: nowrap;
    z-index: 1;
  }
}

.payment-options {
  display: grid;
  grid-gap: to-rem(30px);
  grid-template-columns: 1fr 1fr;
  padding: 0 to-rem(30px);
  width: 100%;

  @include phone {
    grid-template-columns: 100%;
    padding: 0;
    .debit-card {
      height: 15rem;
    }
  }

  .bank-card {
    height: 17rem;
    width: 40rem;
  }

  &__subtitle {
    color: $text-color-2;
    font-size: to-rem(16px);
    line-height: to-rem(24px);
  }

  .account-number {
    font-size: to-rem(18px);
    font-weight: $font-semibold;
    line-height: to-rem(28px);

    @include phone {
      font-size: to-rem(14px);
    }
  }

  .bank {
    display: inline;
    font-weight: $font-semibold;
    line-height: to-rem(28px);
     margin-top: 13rem;

    .account-name {
      float: left;
      font-size: to-rem(18px);

      @include phone {
        font-size: to-rem(14px);
      }
    }

    .bank-name {
      float: right;
      font-size: to-rem(14px);
    }
  }
}

.add-card-btn {
  max-width: 100%;
  width: to-rem(345px);
}

.supporting-document {
  display: flex;
  flex-wrap: wrap;

  .doc-box {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 1px dashed $border-color;
    width: 270px;
    height: 185px;
    box-sizing: border-box;
    border-radius: 5px;
    background: $document-background;
    list-style: none;
    margin-bottom: 20px;
    padding: to-rem(15px);
    overflow: hidden;
    text-align: center;

    .icon {
      margin: 0 auto to-rem(10px);
    }

    .document-name {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .view-button {
      background: #fff;
      border: 1px solid $border-color;
      border-radius: 0.4rem;
      box-sizing: border-box;
      cursor: pointer;
      margin: to-rem(10px) auto 0;
      max-width: to-rem(150px);
      padding: 0.2rem 1rem;
    }

    .loan-id {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #06192f;
    }

    .doc-name {
      color: $document-title;
      margin-bottom: 10px;
    }
  }
}

.email-change {
  font-size: to-rem(16px);

  input {
    width: 80%;
  }

  &_link {
    // margin-left: to-rem(350px);

    @include phone {
      margin-left: 0;
    }

    @include tablet {
      margin-left: 0;
    }
  }

  &_note {
    font-size: to-rem(16px);
    margin-bottom: to-rem(20px);
    text-transform: none;
    font-weight: $font-regular;
  }
}

.edit-email {
  &__cancel {
    margin-top: to-rem(15px);
    border: 1px solid;
  }
}

.doc-filter {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  h5 {
    margin: 0;
    font-weight: $font-regular;
    margin-right: to-rem(20px);

    span {
      font-weight: $font-semibold;
    }
  }

  select {
    width: 180px;
    font-size: to-rem(13px);
    margin-right: 10px;
  }
}
