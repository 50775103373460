@import '../../stylesheets/modules/modules';

.new-card {
  align-items: center;
  background: none;
  border: 1px solid transparent;
  border-radius: to-rem(12px);
  cursor: pointer;
  display: flex;
  height: to-rem(150px);
  margin: 0 0 to-rem(30px);
  max-width: to-rem(345px);
  text-align: center;
  width: 100%;
  padding: to-rem(20px);
  position: relative;

  .button-text {
    font-size: to-rem(14px);
    font-weight: $font-semibold;
    width: 100%;
  }

  svg {
    height: to-rem(30px);
    margin-bottom: to-rem(10px);
  }
}

.radio-group {
  &.early-repayment {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.payWtDiffMethod {
  margin: 0;
  display: block;
  top: 20px;
  position: absolute;

  input{
    &:checked + span {
      background: none;
      border-color: transparent;
      box-shadow: none;
      color: $color-black;

      &:before {
        background: none;
        border: to-rem(2px) solid $color-black;
        box-sizing: border-box;
      }

      &:after{
        background: $color-black url("../../static/icons/check-icon.svg") center no-repeat;
      }
    }
  }

  span {
    border: none;
    float: none;
    color: transparent;
    font-size: to-rem(13px);
    line-height: to-rem(27px);
    padding: 0 0 0 to-rem(35px);
    text-transform: uppercase;

    &:before {
      background: none;
      border: to-rem(2px) solid $color-black;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
      height: to-rem(25px);
      left: 0;
      top: 0;
      width: to-rem(25px);
    }

    &:after{
      height: to-rem(21px);
      left: 2px;
      top: 2px;
      width: to-rem(21px);
    }
  }
}
